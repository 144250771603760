<template>
    <div class="w-90 center">
        <div class="flex flex-wrap items-center justify-between pb2 pv4">
            <div>
                <div class="pb3 pb0-l f3 b">{{ title }}</div>
            </div>
        </div>
        <div class="w-50-l w-100 items-center center tc">
            <div>
                <img src="../assets/images/access_denied.svg" alt="access denied" style="width:80%" />
            </div>
            <div class="pv4">Sorry, this feature is not available in your current plan</div>
            <button @click="gotoBilling">Upgrade</button>
        </div>
    </div>
</template>

<script>
import { useRouter } from 'vue-router'
export default {
    name: 'AccessDenied',
    props: {
        title: String,
    },

    setup() {
        const router = useRouter()

        const gotoBilling = () => {
            router.push({ name: 'Plan' })
        }

        return {
            gotoBilling
        }
    },
}
</script>