<template>
    <app-wrapper>
        <template v-slot:child>
            <!-- Top content-->

            <InventoryHeader
                v-if="plan?.businessPlan !== 'Micro Business Plan'" 
                :type="'PRODUCT'" 
                :title="'Products & Services'" 
                @onSearch="onNavigate" 
            />
            <UpdateInventoryModal 
                :closeModal="() => toggleUpdateInventoryModal(false)" 
                @onComplete="refreshHandler" 
            />

            <!-- Body-->

            <!--      <Filter />-->

            <template v-if="$store.state.Inventory?.loading">
                <Spinner />
            </template>

            <!-- desktop / mobile content display -->
            <div v-if="(role === 'owner' || role === 'admin' || rolePermissions?.includes(6)) && plan?.businessPlan !== 'Micro Business Plan'">
                <div class="scrollmenu w-90 center">
                    <table class="w-100 tl mt3" cellspacing="0">
                        <thead>
                            <tr>
                                <!-- <th class="pb3 pr3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>Product ID</span>
                                    </span>
                                </th> -->
                                <th class="pb3 pr5 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>Product Name</span>
                                        <span>
                                            <span class="header-filter-sort-icon">
                                                <span v-if="state.isOpen && getTheValue == 'name'" class="expand sort1"
                                                    @click="sortTransactionsTable('asc', 'name')">
                                                    <img :src="require('@/assets/images/sort-up.png')"
                                                        class="directional-up" style="height:8px; width:7px"
                                                        alt="product" />
                                                </span>
                                                <span v-else class="expand sort1"
                                                    @click="sortTransactionsTable('desc', 'name')">
                                                    <img :src="require('@/assets/images/caret-down.png')"
                                                        class="directional-up" style="height:8px; width:7px"
                                                        alt="product" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </th>
                                <th class="pb3 box-border-bottom tc ph3">
                                    <span class="flex items-center">
                                        <span>Brand</span>
                                    </span>
                                </th>
                                <th class="pb3 box-border-bottom tc ph3">
                                    <span class="flex items-center">
                                        <span>Category</span>
                                    </span>
                                </th>
                                <th class="pb3 box-border-bottom tc">&nbsp; Type &nbsp;</th>
                                <th class="pb3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>Quantity</span>
                                        <span>
                                            <span class="header-filter-sort-icon">
                                                <span v-if="state.isOpen && getTheValue == 'qty_in_stock'"
                                                    class="expand sort1"
                                                    @click="sortTransactionsTable('asc', 'qty_in_stock')">
                                                    <img :src="require('@/assets/images/sort-up.png')"
                                                        class="directional-up" style="height:8px; width:7px"
                                                        alt="product" />
                                                </span>
                                                <span v-else class="expand sort1"
                                                    @click="sortTransactionsTable('desc', 'qty_in_stock')">
                                                    <img :src="require('@/assets/images/caret-down.png')"
                                                        class="directional-up" style="height:8px; width:7px"
                                                        alt="product" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </th>
                                <th class="pb3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>Cost Price</span>
                                        <span>
                                            <span class="header-filter-sort-icon">
                                                <span v-if="state.isOpen && getTheValue == 'buyingPrice'" class="expand sort1"
                                                    @click="sortTransactionsTable('asc', 'buyingPrice')">
                                                    <img :src="require('@/assets/images/sort-up.png')"
                                                        class="directional-up" style="height:8px; width:7px"
                                                        alt="product" />
                                                </span>
                                                <span v-else class="expand sort1"
                                                    @click="sortTransactionsTable('desc', 'buyingPrice')">
                                                    <img :src="require('@/assets/images/caret-down.png')"
                                                        class="directional-up" style="height:8px; width:7px"
                                                        alt="product" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </th>
                                <th class="pb3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>Sales Price</span>
                                        <span>
                                            <span class="header-filter-sort-icon">
                                                <span v-if="state.isOpen && getTheValue == 'sellingPrice'" class="expand sort1"
                                                    @click="sortTransactionsTable('asc', 'sellingPrice')">
                                                    <img :src="require('@/assets/images/sort-up.png')"
                                                        class="directional-up" style="height:8px; width:7px"
                                                        alt="product" />
                                                </span>
                                                <span v-else class="expand sort1"
                                                    @click="sortTransactionsTable('desc', 'sellingPrice')">
                                                    <img :src="require('@/assets/images/caret-down.png')"
                                                        class="directional-up" style="height:8px; width:7px"
                                                        alt="product" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </th>
                                <th class="pb3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>Stock Value</span>
                                        <span>
                                            <span class="header-filter-sort-icon">
                                                <span v-if="state.isOpen && getTheValue == 'purchaseStockValue'"
                                                    class="expand sort1"
                                                    @click="sortTransactionsTable('asc', 'purchaseStockValue')">
                                                    <img :src="require('@/assets/images/sort-up.png')"
                                                        class="directional-up" style="height:8px; width:7px"
                                                        alt="product" />
                                                </span>
                                                <span v-else class="expand sort1"
                                                    @click="sortTransactionsTable('desc', 'purchaseStockValue')">
                                                    <img :src="require('@/assets/images/caret-down.png')"
                                                        class="directional-up" style="height:8px; width:7px"
                                                        alt="product" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </th>
                                <th class="pb3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>Amount Sold</span>
                                        <span>
                                            <span class="header-filter-sort-icon">
                                                <span v-if="state.isOpen && getTheValue == 'sales_cost'"
                                                    class="expand sort1"
                                                    @click="sortTransactionsTable('asc', 'sales_cost')">
                                                    <img :src="require('@/assets/images/sort-up.png')"
                                                        class="directional-up" style="height:8px; width:7px"
                                                        alt="product" />
                                                </span>
                                                <span v-else class="expand sort1"
                                                    @click="sortTransactionsTable('desc', 'sales_cost')">
                                                    <img :src="require('@/assets/images/caret-down.png')"
                                                        class="directional-up" style="height:8px; width:7px"
                                                        alt="product" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </th>
                                <th class="pb3 box-border-bottom tc">Action</th>
                            </tr>
                        </thead>

                        <template v-if="state.allProducts?.data?.length">
                            <template v-for="product of state.allProducts?.data" v-bind:key="product._id">
                                <tr class="font-w1 pointerx">
                                    <!-- <td class="pv1 pr4 box-border-bottom tl">{{ product._id.toUpperCase().slice(1, 10) }}</td> -->

                                    <template
                                        v-if="role === 'admin' || role === 'owner' || rolePermissions?.includes(8)">
                                        <router-link :to="{ name: 'Product', params: { id: product._id } }">
                                            <td class="pv1 pr4 box-border-bottom tl tooltip flex items-center"
                                                :class="$store.state.Alert?.showMobileMenu && 'unZIndex'">
                                                <img :src="product?.imageUrl || require('@/assets/images/inventory-default.png')"
                                                    class="pr1 h-25 w-25 cover"
                                                    style="object-position: center; height: 40px; width: 40px; gap: 1rem"
                                                    :alt="product.name" />
                                                <span class="font-w2">{{
                                                    product.name.length > 25 ? `${product.name.slice(0, 25)}...` :
                                                    product.name
                                                    }}</span>
                                                <span v-if="product?.name?.length > 25" class="tooltiptext"
                                                    style="padding: 10px;">{{ product?.name }}</span>
                                            </td>
                                        </router-link>
                                    </template>

                                    <td v-else class="pv1 pr4 box-border-bottom tl tooltip flex items-center">
                                        <img :src="product?.imageUrl || require('@/assets/images/inventory-default.png')"
                                            class="pr1 h-25 w-25 cover"
                                            style="object-position: center; height: 40px; width: 40px; gap: 1rem"
                                            :alt="product.name" />
                                        <span class="font-w2">{{
                                            product.name.length > 25 ? `${product.name.slice(0, 25)}...` : product.name
                                            }}</span>
                                        <span v-if="product?.name?.length > 25" class="tooltiptext"
                                            style="padding: 10px;">{{
                                            product?.name
                                            }}</span>
                                    </td>

                                    <td class="pv1 pr4 box-border-bottom tc tooltip">
                                        {{
                                        product?.brandName?.length > 10
                                        ? `${product?.brandName?.slice(0, 20)}...`
                                        : product?.brandName || '-'
                                        }}
                                        <span v-if="product?.brandName?.length > 20" class="tooltiptext"
                                            style="padding: 10px;">{{
                                            product?.brandName
                                            }}</span>
                                    </td>
                                    <td class="pv1 pr4 box-border-bottom tc tooltip">
                                        <span class="br-pill ph2 pv1" style="background: #d7defa">{{
                                            product?.categoryName?.length > 10
                                            ? `${product?.categoryName.slice(0, 10)}...`
                                            : product?.categoryName || '-'
                                            }}</span>
                                        <span v-if="product?.categoryName?.length > 10" class="tooltiptext"
                                            style="padding: 10px;">{{ product?.categoryName }}</span>
                                    </td>
                                    <td class="pv1 pr4 box-border-bottom tc">
                                        <span v-if="product.type === 'RAW_MATERIAL'"
                                            class="badge badge-pill-2 badge-danger" title="Raw Material">R</span>
                                        <span v-else-if="product.type === 'PRODUCTION'"
                                            class="badge badge-pill-2 badge-dark" title="Finished Product">F</span>
                                        <span v-else class="badge badge-pill-2 badge-info" title="Product">P</span>
                                    </td>
                                    <td class="pv1 pr4 box-border-bottom tr">
                                        {{ formatQuantity(product?.qty_in_stock || 0) }}
                                    </td>
                                    <td class="pv1 pr4 box-border-bottom tr">
                                        {{ formatAmount(product?.buyingPrice, $store.state.Settings?.currency) }}
                                    </td>
                                    <td class="pv1 pr4 box-border-bottom tr">
                                        {{ formatAmount(product?.sellingPrice, $store.state.Settings?.currency) }}
                                    </td>
                                    <td class="pv1 pr4 box-border-bottom tr">
                                        <span>
                                            {{ formatAmount(
                                            parseFloat(product?.qty_in_stock) * parseFloat(product?.buyingPrice || product?.purchase_avg || 0),
                                            $store.state.Settings?.currency
                                            )
                                            }}
                                        </span>
                                        <!-- <span v-if="product?.purchase_qty && product?.sales_qty">
                                            {{
                                            formatAmount(
                                            (parseFloat(product?.purchase_qty) - parseFloat(product?.sales_qty)) *
                                            product?.buyingPrice,
                                            $store.state.Settings?.currency
                                            )
                                            }}
                                        </span>

                                        <span v-else>
                                            {{
                                            formatAmount(
                                            product.purchase_qty * product?.buyingPrice,
                                            $store.state.Settings?.currency
                                            )
                                            }}
                                        </span> -->
                                    </td>
                                    <td class="pv1 pr4 box-border-bottom tr">
                                        {{ formatAmount(product?.sales_cost, $store.state.Settings?.currency) }}
                                    </td>
                                    <td class="pv1 pr4 box-border-bottom tc">
                                        <a v-if="role === 'admin' || role === 'owner' || rolePermissions.includes(36)" href="" @click.prevent="toggleUpdateInventoryModal(true, product)"
                                            title="Update Inventory" class="mr2">
                                            <font-awesome-icon icon="fa-solid fa-plus-minus" class="fa-2x" />
                                        </a>
                                        <a v-if="role === 'admin' || role === 'owner' || rolePermissions.includes(40)" href="" @click.prevent="archive(product)" title="Archive" class="tc">
                                            <img :src="require('@/assets/images/delete.svg')" alt="archive" />
                                        </a>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </table>
                    <TableEmptyState v-if="!state.allProducts?.data?.length" :data-array="state.allProducts?.data" />
                </div>
                <!-- <Pagination v-if="state.allProducts?.data?.length" :pages="state.allProducts?.pagination" route-name="Products"
                    :onNavigate="onNavigate" /> -->

                <div class="scrollmenu w-90 center pt2">
                    <Page
                        v-model="state.allProducts.page"
                        :total-row="state.allProducts?.total"
                        :page-size-menu="[20,50,100]"
                        @change="onNavigate"
                        language="en"
                    />
                </div>
            </div>
            <div v-if="plan?.businessPlan && plan?.businessPlan === 'Micro Business Plan'">
                <access-denied title="Access Denied" />
            </div>
        </template>
    </app-wrapper>
</template>

<script>
    import { onMounted, computed, ref, reactive } from 'vue'
    import { useStore } from 'vuex'
    import { useRoute, useRouter } from 'vue-router'
    import Swal from 'sweetalert2'
    import { formatAmount, formatQuantity, sortAlphaNumericData, sortByNumericData } from '@/utils/lib'
    import AppWrapper from '@/layout/AppWrapper'
    import InventoryHeader from '@/views/Inventory/widgets/InventoryHeader'
    import Pagination from '@/components/Pagination'
    import { Page } from 'v-page'
    import AccessDenied from '@/components/AccessDenied'

    import Spinner from '@/components/Spinner'
    import TableEmptyState from '@/components/TableEmptyState'
    import UpdateInventoryModal from '@/views/Inventory/widgets/UpdateInventoryModal'
    // import Filter from '@/components/Filter'

    export default {
        name: 'AllProducts',
        components: { AppWrapper, InventoryHeader, Pagination, Page, AccessDenied, Spinner, TableEmptyState, UpdateInventoryModal },

        data: () => ({
            isOpen: true
        }),

        setup() {
            const router = useRouter()
            const store = useStore()
            const allProducts = computed(() => store?.state.Inventory.products)
            // const productPages = computed(() => store?.state.Inventory.productPages)
            const role = computed(() => store?.state?.Auth?.role)
            const route = useRoute()
            const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
            const plan = computed(() => store.state?.Settings?.paymentPlan)
            const getTheValue = ref('')

            const state = reactive({
                allProducts: { data: [], page: 1, skip: 0, total: 0 },
                isOpen: true
            })

            const onNavigate = (query) => {
                const queryFilter = {
                    q: route.query?.q || query?.q || '',
                    filterType: query?.filterType || route.query?.filterType || 'customrange',
                    filterByCategory: query?.category || route.query?.category || '',
                    filterByBrand: query?.brand || route.query?.brand || '',
                    filterByQuantity: query?.quantity || route.query?.quantity || '',
                    filterByCost: query?.cost || route.query?.cost || '',
                    filterByTags: query?.tags || route.query?.tags || '',
                    limit: query?.pageSize || query?.limit || route.query?.limit || 20,
                    page: query?.pageNumber || query?.page || route.query?.page || 1,
                    sort: query?.sort || route.query?.sort || 'updatedAt',
                    mode: query?.mode || route.query?.mode || 'desc',
                }

                if (queryFilter.q && queryFilter.q !== '') {
                    queryFilter.filterType = 'search'
                }
                
                store.dispatch('Inventory/getAllProducts', queryFilter).then((data) => {
                    state.allProducts = data
                })
            }

            const handleClickedProduct = id => {
                if (role?.value === 'admin' || role?.value === 'owner') {
                    router.push({ name: 'Product', params: { id } })
                }
            }

            onMounted(() => {
                const query = {
                    filterType: route.query?.filterType || 'customrange',
                    filterByCategory: route.query?.category || '',
                    filterByBrand: route.query?.brand || '',
                    filterByQuantity: route.query?.quantity || '',
                    filterByCost: route.query?.cost || '',
                    filterByTags: route.query?.tags || '',
                    limit: route.query?.limit || 20,
                    page: route.query?.page || 1,
                    sort: route.query?.sort || 'updatedAt',
                    mode: route.query?.mode || 'desc',
                }
                
                store.dispatch('Inventory/getAllProducts', query).then((data) => {
                    state.allProducts = data
                })
                store.dispatch('Settings/getPlan')
            })

            const refreshHandler = () => {
                const query = { limit: 25, page: 1, sort: 'updatedAt', mode: 'desc' }
                store.dispatch('Inventory/getAllProducts', query)
            }

            const sortTransactionsTable = (mode, sortBy) => {
                state.isOpen = !state.isOpen
                getTheValue.value = sortBy
                const query = {
                    filterType: route.query.filterType || 'customrange',
                    limit: route.query.limit || 20,
                    page: route.query.page || 1,
                    sort: route.query.sort || sortBy,
                    mode: route.query.mode || mode,
                }

                store.dispatch('Inventory/getAllProducts', query)
            }

            const archive = product => {
                const quantity = product?.qty_in_stock ? parseInt(product?.qty_in_stock || 0) : 0
                if (quantity != 0) {
                    Swal.fire(`Sorry, you can only archive a product with 0 quantity in stock`, '', 'info')
                    return
                }

                Swal.fire({
                    title: `Are you sure you want to archive this product - ${product.name}?`,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Yes, archive it!',
                    denyButtonText: `No, cancel!`
                }).then(result => {
                    if (result.isConfirmed) {
                        store.dispatch('Inventory/archiveProduct', product._id)
                    }
                })
            }

            const toggleUpdateInventoryModal = (status, product = null) => {
                store.dispatch('Inventory/toggleUpdateInventoryModal', { status, product })
            }

            return {
                state,
                allProducts,
                handleClickedProduct,
                formatAmount,
                refreshHandler,
                formatQuantity,
                onNavigate,
                role,
                sortTransactionsTable,
                archive,
                rolePermissions,
                toggleUpdateInventoryModal,
                getTheValue,
                plan,
            }
        }
    }
</script>

<style scoped>
    .tooltip .tooltiptext {
        margin-left: -100px;
    }
</style>